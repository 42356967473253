export default {
  name: 'Silkster Business Template',
  address: {
    line1: '1234 Template Place',
    city: 'Silver Spring',
    state: 'MD',
    postalcode: '20901',
  },
  phone: {
    number: '202-596-2171',
    countryCode: '1',
  },
};
