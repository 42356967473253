export default {
  isDesktop: null,
  isLarge: null,
  isMobile: null,
  isSmall: null,
  isTablet: null,
  isLandscape: null,
  isPortrait: null,
  screen: null,
};
