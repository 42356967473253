import viewport from './viewport';

export default {
  api: {
    contact: {
      url: 'https://cxana18m0g.execute-api.us-east-2.amazonaws.com/default/sendEmailToSilkster',
    },
  },
  recaptcha: {
    key: '6Lfsd_UkAAAAACqrQFNgYSoaIFSbDPW9TpmrGKDX',
  },
  viewport,
};
